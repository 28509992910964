import React from "react"
import Smile from "./smile"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { useSpring, animated, config } from 'react-spring'


export default function AltHeader(props) {
  const slide1 = useSpring({
    to: [{top: "5em"}],
    from: {top: "-5em"},
    config: config.gentle,
    delay: 500
  });
  const slide2 = useSpring({
    to: [{left: "0"}],
    from: {left: "-5em"},
    config: config.gentle,
    delay: 700
  });
  const slide3 = useSpring({
    to: [{right: "0"}],
    from: {right: "-5em"},
    config: config.gentle,
    delay: 900
  });
  const slide4 = useSpring({
    to: [{width: "100%", opacity: 1}],
    from: {width: "0%", opacity: 0},
    config: config.slow,
    delay: 1500
  });


  return (
    <header className="w-full lg:h-full lg:absolute top-0 left-0 flex flex-col items-center p-6">
      <section className="w-32 mb-6 lg:mb-0 z-1 lg:absolute top-0 right-0 lg:mt-6 lg:mr-6">
        <Link to="/">
          <Smile className="w-full"/>
        </Link>
      </section>
      <nav className="z-1">
        <ul className="flex">
          <animated.li
            className="mx-4 lg:m-0 lg:absolute"
            style={slide1}
            css={css`
              @media screen and (min-width: 1024px) {
                right: 10em;
                top: 5em;
                width: 350px;
              }
            `
          }>
            <animated.div
              style={slide4}
              css={css`
                @media screen and (min-width: 1024px) {
                  position: absolute;
                  height: 2px;
                  width: 0%;
                  background: #777;
                  // animation: ani-open 1s ease-in forwards;
                  // animation-delay: 1s;
                }
              `
            }></animated.div>
            <Link to={props.pages[0].url} className="uppercase text-xl lg:text-2xl">
              {props.pages[0].name}
            </Link>
          </animated.li>
          <animated.li
            className="mx-4 lg:m-0 lg:absolute lg:left-0"
            style={slide2}
            css={css`
              @media screen and (min-width: 1024px) {
                top: 50%;
                bottom: 50%;
                transform: rotate(-90deg);
              }
            `
          }>
            <Link to={props.pages[1].url} className="uppercase text-xl">
              {props.pages[1].name}
            </Link>
          </animated.li>
          <animated.li
            className="mx-4 lg:m-0 lg:absolute lg:right-0"
            style={slide3}
            css={css`
              @media screen and (min-width: 1024px) {
                top: 50%;
                bottom: 50%;
                transform: rotate(90deg);
              }
            `
          }>
            <Link to={props.pages[2].url} className="uppercase text-xl">
              {props.pages[2].name}
            </Link>
          </animated.li>
        </ul>
      </nav>
    </header>
  )
}
