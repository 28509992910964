import React from "react"
import cello from "../images/cello-texture.svg"
import halftone from "../images/halftone.svg"
import { css } from '@emotion/core';


export default function Textures(props) {

  return (
    <div>
      <div
        style={{backgroundImage: `url(${cello})`, mixBlendMode: "screen"}}
        className="absolute w-full h-screen bg-no-repeat bg-center bg-cover left-0 top-0"
      ></div>
      <div
        style={{backgroundImage: `url(${halftone})`, height: "50%", transform: "rotateY(180deg) rotateX(180deg)", opacity: "0.1"}}
        className="w-2/5 absolute bg-no-repeat bg-center bg-cover overflow-hidden"
        css={css`
          top: 0;
          left: -90px;
          max-height: 489px;

          @media screen and (min-width: 1024px) {
            left: 0;
          }
        `
      }></div>
      <div
        style={{backgroundImage: `url(${halftone})`, height: "50%", opacity: "0.1"}}
        className="w-2/5 absolute bg-no-repeat bg-center bg-cover overflow-hidden"
        css={css`
          bottom: 0;
          right: -90px;
          max-height: 489px;

          @media screen and (min-width: 1024px) {
            right: 0;
          }
        `
      }></div>
    </div>
  )
}
